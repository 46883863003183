<template>
  <b-card>
    <b-card-header>
      <b-card-title>
        {{ title }}
      </b-card-title>
      <b-button
        variant="primary"
        @click="$router.push({ name: 'newReclamation', params: { }})"
      >
        {{ $t("NEW_RECLAMATION_BUTTON") }}
      </b-button>
    </b-card-header>
    <b-table
      :items="reclamations"
      responsive
      :fields="fields"
      show-empty
      :empty-text="$t('NO_RESULT_TABLE')"
      primary-key="id"
      :sort-by.sync="sortBy"
      :sort-desc.sync="isSortDirDesc"
      class="position-relative"
    >
      <template #cell(action)="data">
        <b-button
          v-if="can_access(data.item)"
          variant="outline-info"
          class="btn-icon"
          @click="$router.push({ path: `/reclamation/${data.item.id}`})"
        >
          <feather-icon icon="EyeIcon" />
        </b-button>
      </template>
      <template #cell(numero)="data">
        {{ data.item.id }}
      </template>

      <template #cell(libelle)="data">
        <div v-if="data.item.title.length<8">
          {{ data.item.title }}
        </div>
        <div v-else>
          {{ data.item.title.substring(0,30)+".." }}
        </div>
      </template>

      <template #cell(emetteur)="data">
        {{ data.item.createdBy.firstName }} {{ data.item.createdBy.lastName }}
      </template>

      <template #cell(type)="data">
        {{ $t(data.item.reclamationType.name) }} - {{ $t(data.item.reclamationSubType.name) }}
      </template>

      <template #cell(userTechnique)="data">
        <template v-if="data.item.userTechnique">
          {{ data.item.userTechnique.firstName }} {{ data.item.userTechnique.lastName }}
        </template>
        <template v-else>
          <center>
            <b-avatar
              variant="light-danger"
            >
              <feather-icon icon="SlashIcon" />
            </b-avatar>
          </center>
        </template>
      </template>

      <template #cell(createdAt)="data">
        {{ data.item.createdAt | luxon({ output: "dd/MM/y HH:mm" }) }}
      </template>

      <template #cell(updatedAt)="data">
        <p v-if="data.item.updatedAt">
          {{ data.item.updatedAt | luxon({ output: "dd/MM/y HH:mm" }) }}
        </p>
      </template>

      <template #cell(statut)="data">
        <b-badge
          :variant="variantStatut(data.item.status)"
        >
          {{ $t(data.item.status) }}
        </b-badge>
      </template>

    </b-table>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BButton, BCardTitle, BBadge, BAvatar,
  BTable, VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BAvatar,
    BCard,
    BTable,
    BBadge,
    BCardHeader,
    BButton,
    BCardTitle,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    title: {
      type: String,
      default: () => '',
    },
    reclamations: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      sortBy: 'id',
      isSortDirDesc: true,
      fields: [
        { key: 'action', label: '', sortable: false },
        { key: 'numero', label: this.$t('HEADER_TABLE_NUMBER'), sortable: true },
        { key: 'libelle', label: this.$t('HEADER_TABLE_RECLAMATION_TITLE'), sortable: true },
        { key: 'createdAt', label: this.$t('HEADER_TABLE_CREATED_AT'), sortable: true },
        { key: 'type', label: this.$t('HEADER_TABLE_RECLAMATION_TYPE'), sortable: true },
        { key: 'emetteur', label: this.$t('HEADER_TABLE_RECLAMATION_CREATOR'), sortable: true },
        { key: 'statut', label: this.$t('HEADER_TABLE_RECLAMATION_STATUS'), sortable: true },
        { key: 'userTechnique', label: this.$t('HEADER_TABLE_RECLAMATION_TECHNINCIAN'), sortable: true },
        { key: 'updatedAt', label: this.$t('HEADER_TABLE_UPDATED_AT'), sortable: true },
      ],
    }
  },
  methods: {
    variantStatut(statut) {
      let returnStatut = 'secondary'
      // eslint-disable-next-line default-case
      switch (statut) {
        case 'NEW':
          returnStatut = 'danger'
          break
        case 'ASSIGNED':
        case 'PROCESSING':
          returnStatut = 'warning'
          break
        case 'RESOLVED':
          returnStatut = 'success'
          break
        case 'CLOSED':
          returnStatut = 'danger'
          break
      }
      return returnStatut
    },
    can_access(reclamation) {
      let canAccess = false
      const idCurrentUser = JSON.parse(localStorage.userData).id
      if ((reclamation.userClient && reclamation.userClient.id === idCurrentUser) || (reclamation.userTechnique && reclamation.userTechnique.id === idCurrentUser)) {
        canAccess = true
      }
      if (JSON.parse(localStorage.userData).role === 'superviseur') {
        canAccess = true
      }
      if (JSON.parse(localStorage.userData).company === null) {
        canAccess = true
      }
      canAccess = true
      return canAccess
    },
  },
}
</script>
