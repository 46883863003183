<template>
  <b-row class="match-height">
    <b-col
      xl="12"
      lg="12"
      md="12"
      xs="12"
    >
      <dashboard-stats :data="statisticsItems" />
    </b-col>

    <b-col>
      <b-card>
        <b-overlay
          :show="showOverlay"
          rounded="xl"
          variant="transparent"
          opacity="1"
          blur="5px"
        >
          <week-reclamation
            :title="$t('DASHBOARD_TITLE_MY_LAST_7_DAY_RECLAMATION')"
            :reclamations="myReclamation"
          />
          <week-reclamation
            :title="$t('DASHBOARD_TITLE_ALL_LAST_7_DAY_RECLAMATION')"
            :reclamations="allReclamations"
          />
        </b-overlay>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BOverlay, BRow, BCol, BCard,
} from 'bootstrap-vue'

import weekReclamation from './reclamation/list/weekReclamation.vue'
import dashboardStats from './dashboardStats.vue'

export default {
  components: {
    BOverlay,
    BCol,
    BRow,
    BCard,
    weekReclamation,
    dashboardStats,
  },
  data() {
    return {
      showOverlay: false,
      myReclamation: [],
      allReclamations: [],
      statisticsItems: [],
    }
  },
  mounted() {
    this.showOverlay = true
    this.$store.dispatch('reclamations/last7DaysReclamations')
      .then(r => {
        this.allReclamations = r
        const idCurrentUser = JSON.parse(localStorage.userData).id

        r.forEach(reclamation => {
          if ((reclamation.userClient && reclamation.userClient.id === idCurrentUser) || (reclamation.userTechnique && reclamation.userTechnique.id === idCurrentUser)) {
            this.myReclamation.push(reclamation)
          }
        })
        this.showOverlay = false
      }).catch(() => {
        this.$router.push('/pages/error-404')
      })

    this.$store.dispatch('reclamations/stats')
      .then(r => {
        this.statisticsItems = [
          {
            icon: 'SlashIcon',
            color: 'light-danger',
            title: r.noAssigned,
            subtitle: 'DASHBOARD_STATS_UNASSIGNED',
            customClass: 'mb-2 mb-sm-0',
          },
          {
            icon: 'LayersIcon',
            color: 'light-info',
            title: r.all,
            subtitle: 'DASHBOARD_STATS_ALL_RECLAMATION',
            customClass: 'mb-2 mb-xl-0',
          },
          {
            icon: 'FilePlusIcon',
            color: 'light-warning',
            title: r.inProgress,
            subtitle: 'DASHBOARD_STATS_PROCESSING',
            customClass: 'mb-2 mb-xl-0',
          },
          {
            icon: 'InboxIcon',
            color: 'light-success',
            title: r.resolved,
            subtitle: 'DASHBOARD_STATS_RESOLVED',
            customClass: '',
          },
          {
            icon: 'InboxIcon',
            color: 'light-primary',
            title: r.closed,
            subtitle: 'DASHBOARD_STATS_CLOSED',
            customClass: '',
          },
        ]
      })
  },
}
</script>

<style lang="scss">
</style>
